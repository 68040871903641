<template>
  <div>
    <template>
      <v-row>
        <v-col md="2">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Generar reportes
                <v-icon dark right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :key="0" @click="visibleModalInforme = true">
                <v-list-item-title>Mendoza Activa II</v-list-item-title>
              </v-list-item>
              <v-list-item :key="1" @click="visibleModalInformeEnlace = true">
                <v-list-item-title>Programa ENLACE</v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="2"
                @click="visibleModalInformeComprobante = true"
              >
                <v-list-item-title>Informe Comprobante</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <ModalInforme
            :visibleModalInforme.sync="visibleModalInforme"
          ></ModalInforme>
          <ModalInformeEnlace
            :visibleModalInformeEnlace.sync="visibleModalInformeEnlace"
          >
          </ModalInformeEnlace>
          <ModalInformeComprobante
            :visibleModalInformeComprobante.sync="
              visibleModalInformeComprobante
            "
          >
          </ModalInformeComprobante>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="buscarCuit"
            label="Número de DNI/CUIT/CUIL"
            type="number"
            pattern="/^[0-9]{1,12}$/"
          >
          </v-text-field>
          <p v-if="buscarCuit.length > 11" style="color: red">
            Max. 11 caracteres
          </p>
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" dark @click="buscar(buscarCuit)">
            Buscar
          </v-btn></v-col
        >
        <div class="col-lg-6">
          <div style="float: right">
            <v-col md="2">
              <v-btn
                class="boton-exportar"
                color="primary"
                :loading="loadingExcel"
                @click="exportarExcel()"
                block
                >Exportar Excel</v-btn
              >
              <br />
            </v-col>
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="filtroFideicomiso"
            :items="fideicomisos.map((el) => el.nombre)"
            label="Fideicomiso"
            @change="getOperatoria(filtroFideicomiso)"
          >
            ></v-select
          >
        </v-col>
        <v-col>
          <v-select
            v-model="filtroOperatoria"
            :items="operatorias.map((el) => el.nombre)"
            label="Operatoria"
            @change="getClientes(filtroOperatoria, (param = 47))"
          >
            ></v-select
          >
        </v-col>
        <v-col
          cols="2"
          v-if="filtroFideicomiso === 'Fideicomiso Mendoza Activa'"
        >
          <v-layout>
            <v-text-field
              v-model="filtroNroTicket"
              v-show="!isOperMinisterio"
              label="Número de Ticket"
              type="number"
              @keyup.enter="getTicket(filtroNroTicket)"
            ></v-text-field>
            <v-checkbox
              v-show="tituloOperatoria === 'COMERCIO + RENOVADO - MZA ACTIVA '"
              v-model="showLegajosAsignados"
              label="Legajos Asignados"
              @change="toggleLegajosAsignados"
            ></v-checkbox>
          </v-layout>
        </v-col>
      </v-row>
      <v-row class="v-row justify-center">
        <div>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="filteredItems"
              item-key="id"
              :loading="loading"
              :no-data-text="'No hay resultados'"
              :footer-props="{
                'show-current-page': true,
              }"
              @click:row="goToGestionar"
            >
              <template v-slot:item.monto_solicitado="{ item }">
                <span class="amount-column">
                  $ {{ formatCurrency(item.monto_solicitado) }}
                </span>
              </template>
              <template v-slot:item.gestionar="{ item }">
                <v-btn
                  tag="a"
                  :to="{
                    name: 'Gestionar',
                    params: {
                      id: item.id,
                      legajos: listaLegajos ? listaLegajos : [],
                    },
                  }"
                  color="primary"
                  >Gestionar
                </v-btn>
              </template>
              <template
                v-for="header in headers"
                v-slot:[`header.${header.value}`]="{ header }"
              >
                <v-text-field
                  v-if="header.customFilter"
                  class="filter-input"
                  v-model="filters[header.value]"
                  append-icon="mdi-magnify"
                  clearable
                  hide-details
                  solo
                  single-line
                ></v-text-field>
                <v-text-field
                  v-if="!header.customFilter"
                  class="filter-input-disabled"
                  hide-details
                  solo
                  single-line
                  disabled
                ></v-text-field>
                <span class="column-title">{{ header.text }}</span>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-row>
    </template>
    <v-card>
      <template v-slot:item.id="{ item }">
        <v-menu offset-y>
          <v-list>
            <v-list-item>
              <v-btn
                text
                :to="{
                  name: 'Gestionar',
                  params: {
                    id: item.id,
                    legajos: listaLegajos,
                  },
                }"
                color="primary"
              >
              </v-btn>
            </v-list-item>
            <v-list-item
              v-if="
                item.estado === 2 &&
                !item.acta &&
                item.dictamen &&
                item.tipo_operatoria_id == 13
              "
            >
              <ModalActaContrato
                nombre="Acta"
                icon="mdi-note-plus-outline"
                :solicitudId="item.id"
                :solicitudNombre="item.nombre"
                :tipo="true"
              ></ModalActaContrato>
            </v-list-item>
            <v-list-item
              v-if="
                item.estado === 2 &&
                item.informe_elevacion === null &&
                !!item.dictamen
              "
              :solicitudNombre="item.nombre"
            >
              <ModalInformeElevacion
                nombre="Informe de Elevación"
                icon="mdi-note-plus-outline"
                :solicitudId="item.id"
              ></ModalInformeElevacion>
            </v-list-item>
            <v-list-item
              v-if="
                item.estado === 2 &&
                !item.contrato &&
                item.acta &&
                item.dictamen &&
                item.informe_elevacion
              "
            >
              <ModalActaContrato
                nombre="Contrato"
                icon="mdi-notebook-plus-outline"
                :solicitudId="item.id"
                :solicitudNombre="item.nombre"
                :tipo="false"
              ></ModalActaContrato>
            </v-list-item>
            <v-list-item v-if="item.contrato && item.documentacion_aprobada">
              <v-btn
                class="mr-3"
                @click="aprobarLegajo(item.id, 6, item.cliente_id)"
                color="success"
              >
                Aprobar Solicitud
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-card>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import ModalActaContrato from '@/components/ModalActaContrato.vue';
import ModalInformeElevacion from '@/components/ModalInformeElevacion.vue';
import ModalInforme from '@/components/ModalInforme.vue';
import ModalInformeEnlace from '@/components/ModalInformeEnlace.vue';
import ModalInformeComprobante from '@/components/ModalInformeComprobante.vue';
import moment from 'moment';
import axios from 'axios';
import xlsx from 'xlsx';

export default {
  name: 'Pendientes',
  components: {
    ModalActaContrato,
    ModalInformeElevacion,
    ModalInforme,
    ModalInformeEnlace,
    ModalInformeComprobante,
  },
  data: () => ({
    showLegajosAsignados: false,
    loadingExcel: false,
    isOperMinisterio: false,
    buscarCuit: '',
    filtros: {
      lcc_id: '',
      cliente: '',
      tipo_operatoria_id: '',
      estado_id: '',
      cuitcuil: '',
      order_fecha: false,
      numero_ticket: '',
      numero_acta: '',
    },
    options: {},
    selected: [],
    headers: [
      {
        text: 'N° Legajo',
        value: 'id',
        align: 'center',
        width: 200,
        sortable: true,
        customFilter: (value, item) => item.id.includes(value),
      },
      {
        text: 'Fecha Ingreso',
        value: 'fecha_estado_analisis',
        align: 'center',
        width: 200,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.fecha_estado_analisis)
            .format('DD/MM/YYYY')
            .includes(value),
      },
      {
        text: 'CUIT/CUIL',
        value: 'cuit',
        align: 'center',
        width: 250,
        sortable: false,
        customFilter: (value, item) => item.dniCuit.includes(value),
      },
      {
        text: 'Cliente',
        value: 'cliente',
        align: 'center',
        width: 460,
        sortable: true,
        customFilter: (value, item) =>
          item.cliente.includes(value.toUpperCase()),
      },

      {
        text: 'Estado',
        value: 'estado',
        align: 'center',
        width: 260,
        sortable: true,
        customFilter: (value, item) =>
          item.estado.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Monto Solicitado',
        value: 'monto_solicitado',
        align: 'center',
        width: 200,
        sortable: false,
      },
      {
        text: 'Fecha Liquidación',
        value: 'fecha_liquidacion',
        align: 'center',
        width: 200,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.fecha_liquidacion).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Gestionar',
        value: 'gestionar',
        align: 'center',
        width: 160,
        sortable: false,
      },
    ],
    filters: {},
    loading: false,
    liquidado: false,
    search: '',
    searchDataTable: '',
    nombreEstado: '',
    filtroOperatoria: '',
    filtroFideicomiso: '',
    tituloOperatoria: '',
    filtroNroTicket: '',
    cliente_id: '',
    totalItems: 0,
    operatorias: [],
    fideicomisos: [],
    fideicomisosId: '',
    solicitudes: [],
    fideicomisosCliente: [],
    lineasCreditoCliente: [],
    listaSolicitudEstados: [],
    listaLegajos: [],
    listaClientes: [],
    listaClientesMdzActiva: [],
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
    noresult: false,
    visibleModalInforme: false,
    visibleModalInformeEnlace: false,
    visibleModalInformeComprobante: false,
  }),
  watch: {
    options: {
      handler() {
        // this.getData();
      },
    },
  },
  computed: {
    resetFilters() {
      this.filters = {};
      // this.filtroFideicomiso = '';
      this.filtroOperatoria = '';
      // this.filteredItems = this.items.slice();
    },
    filteredItems() {
      const rolId = parseInt(localStorage.getItem('rol'), 10);

      const filteredItems = this.solicitudes.filter((item) => {
        if (this.showLegajosAsignados && rolId === 2) {
          return item.estado === 'Aprobado' && item.docAuditado;
        }
        if (this.showLegajosAsignados && rolId === 9) {
          return !item.docAuditado;
        }

        if (this.filters['id']) {
          const filterValue = parseInt(this.filters['id'], 10);
          return item['id'] === filterValue;
        }
        return Object.keys(this.filters).every((key) => {
          if (key === 'nro_legajo') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          if (key === 'fecha_liquidacion') {
            const formattedItemDate = moment(item.fecha_liquidacion).format(
              'DD/MM/YYYY'
            );
            return formattedItemDate.includes(filterValue);
          }
          if (key === 'fecha_estado_analisis') {
            const formattedItemDate = moment(item.fecha_estado_analisis).format(
              'DD/MM/YYYY'
            );
            return formattedItemDate.includes(filterValue);
          }

          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      const filteredLegajoIds = filteredItems.map((item) => item.id);

      // Update listaLegajos
      this.listaLegajos = filteredLegajoIds;

      return filteredItems;
    },
  },
  methods: {
    toggleLegajosAsignados() {
      //This function will now trigger the filter
      this.$forceUpdate(); // this line forces vue to re-render, crucial for the filter to correctly update.
    },
    formatCurrency(value) {
      if (value === 'null') {
        return value;
      }
      const numericValue = parseFloat(value);
      if (isNaN(numericValue)) {
        return value;
      }
      const formatter = new Intl.NumberFormat('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return formatter.format(numericValue);
    },
    async filtrarClientes() {
      if (this.search) {
        if (this.search.length !== 0) {
          const url = `${API_URL}api/clientes/buscar`;
          const data = {
            q: this.search,
          };
          await axios.post(url, data, this.header).then((response) => {
            if (response.data.status === 'success') {
              this.listaClientes = response.data.clientes;
            }
          });
        }
      }
    },
    getOperatoria(fideicomisoFilt) {
      this.filtroNroTicket = '';
      const fideicomisos = this.fideicomisos.filter((el) => {
        if (el.nombre === `${fideicomisoFilt}`) return el.id;
      });
      this.fideicomisosId = fideicomisos[0].id ? fideicomisos[0].id : '';

      axios
        .get(
          `${API_URL}api/servicio/tipoServicioXfideicomiso/list/${this.fideicomisosId}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          this.operatorias = response.data.listado;
          if (this.isOperMinisterio === true) {
            //habilito operatorias para el operator ministerio
            this.operatorias = response.data.listado.filter(
              (ele) => ele.id === 132
            );
          }
        });
      this.resetFilters;
    },
    async getClientes(operatoria, params) {
      this.filtroNroTicket = '';
      let param = params;
      if (operatoria === 'Enlace') {
        param = 61;
      }
      if (!param) {
        param = 47;
      }
      this.filtroOperatoria = operatoria ? operatoria : this.filtroOperatoria;
      this.tituloOperatoria = this.filtroOperatoria;
      this.loading = true;
      const fideicomisosId = this.fideicomisosId;
      const idOperatoria = this.operatorias.filter((el) =>
        el.nombre === operatoria ? el.id : ''
      )[0].id;

      const query = {
        method: 'POST',
        headers: {
          token: localStorage.getItem('token'),
        },
        url: `${API_URL}api/clienteParametros/clientes`,
        data: {
          idOperatoria,
          operatoria,
          fideicomisosId: fideicomisosId ? fideicomisosId : 15,
          param,
        },
      };
      const { data } = await axios(query);

      this.solicitudes = data.resultados;
      const ids = this.solicitudes.map((el) => el.id);
      this.listaLegajos = this.solicitudes.map((el) => el.id);
      this.loading = false;
      this.resetFilters;
    },

    async getTicket(ticket) {
      this.filtroOperatoria = '';
      if (!ticket) {
        return;
      }
      this.loading = true;
      const param = 44;
      const fideicomisosId = this.fideicomisosId;
      const query = {
        method: 'POST',
        headers: {
          token: localStorage.getItem('token'),
        },
        url: `${API_URL}api/clienteParametros/clientes`,
        data: {
          fideicomisosId,
          param,
          ticket,
        },
      };
      const { data } = await axios(query);
      this.solicitudes = data.resultados;
      this.filtroOperatoria = this.loading = false;
      this.tituloOperatoria = data.resultados[0].operatoria;
      this.listaLegajos = this.solicitudes.map((el) => el.id);
    },
    async goToGestionar(item) {
      this.$router.push({
        name: 'Gestionar',
        params: {
          legajos: this.listaLegajos,
          id: item.id,
        },
      });
    },
    async buscar(cuit) {
      this.filtroFideicomiso = '';
      this.filtroOperatoria = '';
      if (!cuit || cuit.length < 5) {
        return;
      }
      this.buscarCuit = '';
      this.loading = true;

      const query = {
        method: 'POST',
        headers: {
          token: localStorage.getItem('token'),
        },
        url: `${API_URL}api/clienteParametros/clientesxcuit`,
        data: {
          cuit,
        },
      };
      const { data } = await axios(query);
      this.solicitudes = data.resultados;
      this.tituloOperatoria = data.resultados[0]
        ? data.resultados[0].operatoria
        : '';
      this.listaLegajos = this.solicitudes.map((el) => el.id);

      if (this.isOperMinisterio === true && this.listaLegajos.length > 0) {
        this.solicitudes = this.solicitudes.filter(
          (el) => el.operatoria_id === 132 && el.monto_solicitado !== ''
        );
      }
      this.filtroOperatoria = this.loading = false;
    },
    moment() {
      return moment();
    },

    getFideicomiso() {
      axios
        .get(`${API_URL}api/admin/unidadGestion`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.fideicomisos = response.data.fideicomisos;
          //habilito fideicomiso para el operator ministerio
          if (this.isOperMinisterio === true) {
            this.fideicomisos = response.data.fideicomisos.filter(
              (ele) => ele.nombre === 'Fideicomiso Mendoza Activa'
            );
          }
        });
    },

    async liquidarEnlace() {
      const liquidaciones = await Promise.all(
        this.selected.map(async (el) => {
          const url = `${API_URL}api/solicitud/liquidacionEnlace/${el.id}`;
          const res = await axios.get(url, this.header);
          return res;
        })
      );
      if (liquidaciones[0].data.status === 'success') {
        this.$swal.fire({
          icon: 'success',
          title: 'Liquidación Exitosa',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Algo salio mal',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.solicitudes = [];
      this.filtros.acta_enlace = '';
    },
    async exportarExcel() {
      let montoTotal = 0;
      this.loadingExcel = true;
      let list = this.filteredItems.map((cc) => {
        const montoSolicitado = parseFloat(cc.monto_solicitado);
        montoTotal += montoSolicitado;

        return {
          Legajo: cc.id,
          FechaInscripción: cc.fecha_estado_analisis,
          DNICUIT: cc.cuit,
          Cliente: cc.cliente,
          RazonSocial: cc.razonSocial,
          Operatoria: cc.operatoria,
          Estado: cc.estado,
          montoSolicitado: ` $ ${this.formatCurrency(montoSolicitado)}`,
          FechaLiquidacion: cc.fecha_liquidacion,
        };
      });
      // Agregar una fila adicional con la suma total
      list.push({
        Legajo: '',
        FechaInscripción: '',
        DNICUIT: '',
        Cliente: '',
        RazonSocial: '',
        Operatoria: '',
        Estado: `Total:`,
        montoSolicitado: ` $ ${this.formatCurrency(montoTotal)}`,
        FechaLiquidacion: '',
      });

      let nombre = `Listado Créditos  - ${this.tituloOperatoria}`;
      const cs = xlsx.utils.json_to_sheet(list);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, cs, 'CC');
      xlsx.writeFile(wb, `${nombre}.xlsx`);
      this.loadingExcel = false;
    },
  },

  created() {
    this.getFideicomiso();

    const rolId = parseInt(localStorage.getItem('rol'), 10);
    rolId === 9
      ? (this.isOperMinisterio = true)
      : (this.isOperMinisterio = false);

    this.tituloOperatoria = this.filtroOperatoria;
  },
};
</script>
<style>
th,
td {
  padding: 0 3px !important;
}
i {
  margin-left: 5px !important;
}
input {
  font-size: 14px;
  font-weight: 500;
  color: #1976d2 !important;
}
.v-input__control {
  margin: 5px;
}
.v-input__slot {
  height: 30px;
  min-height: 30px !important;
  margin: 6px 0 0 0;
  padding: 0 6px !important;
}
.filter-input-disabled .v-input__control .v-input__slot {
  background-color: rgb(239, 239, 239) !important;
  visibility: hidden !important;
}
.column-title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 1rem !important;
}
</style>
