<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-btn
          class="primary"
          :disabled="selected.length < 1"
          @click="generarCSV"
          >Descargar Liquidaciones</v-btn
        >
      </v-col>
      <v-col
        cols="3"
        :class="{
          'disabled-table':
            operatoria_selected === 0 || operatoria_selected === null
              ? true
              : false,
        }"
      >
        <CargaBanco
          @informe-cargado="informeCargado"
          :operatoria="operatoria_selected"
        />
      </v-col>
      <v-col cols="3">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Generar reportes
              <v-icon dark right> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :key="0" @click="visibleModalInforme = true">
              <v-list-item-title>Mendoza Activa II</v-list-item-title>
            </v-list-item>
            <v-list-item :key="1" @click="visibleModalInformeEnlace = true">
              <v-list-item-title>Programa ENLACE</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <ModalInforme
          :visibleModalInforme.sync="visibleModalInforme"
        ></ModalInforme>
        <ModalInformeEnlace
          :visibleModalInformeEnlace.sync="visibleModalInformeEnlace"
        >
        </ModalInformeEnlace>
      </v-col>
      <v-col cols="3">
        <v-btn
          v-if="
            !alta_legajo &&
            (operatoria_selected === 20 || operatoria_selected === 23)
          "
          :disabled="selected.length < 1"
          color="primary"
          @click="enviarTrilay"
        >
          <span v-if="!cargando"> Generar Asiento </span>
          <v-progress-circular
            v-if="cargando"
            :size="25"
            color="white"
            indeterminate
          ></v-progress-circular>
        </v-btn>
        <v-btn
          v-if="false"
          :disabled="selected.length < 1"
          color="primary"
          @click="enviarModuloCredito"
        >
          <span v-if="!cargando"> Alta legajo credito </span>
          <v-progress-circular
            v-if="cargando"
            :size="25"
            color="white"
            indeterminate
          ></v-progress-circular>
        </v-btn>

        <v-btn
          v-if="operatoria_selected === 19"
          :disabled="selected.length <= 0"
          class="boton-exportar"
          color="primary"
          :loading="loadingExcel"
          @click="exportarExcel()"
          block
          >Exportar Excel Trilay
        </v-btn>
        <v-progress-circular
          v-if="loadingExcel"
          :size="25"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="select-operatoria">
      <v-col>
        <v-autocomplete
          @change="switchTipoOperatoria"
          v-model="operatoria_selected"
          :items="list_tipo_operatoria ? list_tipo_operatoria : ''"
          auto-select-first
          clearable
          deletable-chips
          small-chips
          solo
          label="Seleccionar Tipo de Operatoria"
          outlined
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <div v-if="operatoria_selected === 23">
      <v-row class="d-flex align-end justify-space-between mb-2 mt-1">
        <v-col cols="5">
          <h2>Programa Enlace</h2>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Legajos seleccionados: </span>
          <v-card width="90">
            <h3 class="d-flex align-center justify-center py-1">
              {{ selected.length }}
            </h3>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Subtotal seleccionados: </span>
          <v-card width="160">
            <h3 class="d-flex align-center justify-center py-1">
              {{ calculateSelectedSubtotal() }}
            </h3>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          loading-text="Cargando legajos..."
          :headers="headersEnlace"
          :items="filteredItemsEnlace"
          item-key="id"
          show-select
          :loading="this.operatoria_selected ? cargando : false"
          :no-data-text="
            this.operatoria_selected && !this.cargando
              ? 'No hay resultados'
              : ''
          "
          :footer-props="{
            'show-current-page': true,
          }"
          v-model="selected"
        >
          <template v-slot:item.nro_legajo="{ item }">
            <span># {{ item.nro_legajo }}</span>
          </template>
          <template v-slot:item.fecha_liquidacion="{ item }">
            <span>{{
              moment(formatDate(item.fecha_liquidacion)).format('DD/MM/YYYY')
            }}</span>
          </template>
          <template v-slot:item.data_total="{ item }">
            <span>{{ formatCurrency(item.data_total) }}</span>
          </template>

          <template
            v-for="header in headersEnlace"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-text-field
              v-if="header.customFilter"
              class="filter-input"
              v-model="filters[header.value]"
              append-icon="mdi-magnify"
              clearable
              hide-details
              solo
              single-line
            ></v-text-field>
            <v-text-field
              v-if="!header.customFilter"
              class="filter-input-disabled"
              hide-details
              solo
              single-line
              disabled
            ></v-text-field>
            <span class="column-title">{{ header.text }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-if="operatoria_selected === 13">
      <v-row class="d-flex align-end justify-space-between mb-2 mt-1">
        <v-col cols="5">
          <h2>Financiamiento</h2>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Legajos seleccionados: </span>
          <v-card width="90">
            <h3 class="d-flex align-center justify-center py-1">
              {{ selected.length }}
            </h3>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Subtotal seleccionados: </span>
          <v-card width="160">
            <h3 class="d-flex align-center justify-center py-1">
              {{ calculateSelectedSubtotal() }}
            </h3>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          loading-text="Cargando legajos..."
          :headers="headersFinanciamiento"
          :items="filteredItemsFinanciamiento"
          item-key="id"
          show-select
          :loading="this.operatoria_selected ? cargando : false"
          :no-data-text="
            !this.cargando && this.success ? 'No hay resultados' : ''
          "
          :footer-props="{
            'show-current-page': true,
          }"
          v-model="selected"
          :item-class="checkFechaDesembolso"
        >
          <template v-slot:item.nro_legajo="{ item }">
            <span># {{ item.nro_legajo }}</span>
          </template>
          <template v-slot:item.fecha_liquidacion="{ item }">
            <span>{{ item.fecha_liquidacion }}</span>
          </template>
          <template v-slot:item.fecha_desembolso="{ item }">
            <span>{{ item.fecha_desembolso ? 'SI' : 'NO' }}</span>
          </template>
          <template v-slot:item.data_total="{ item }">
            <span>{{ formatCurrency(item.data_total) }}</span>
          </template>
          <template
            v-for="header in headersFinanciamiento"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-text-field
              v-if="header.customFilter"
              class="filter-input"
              v-model="filters[header.value]"
              append-icon="mdi-magnify"
              clearable
              hide-details
              solo
              single-line
            ></v-text-field>
            <v-text-field
              v-if="!header.customFilter"
              class="filter-input-disabled"
              hide-details
              solo
              single-line
              disabled
            ></v-text-field>
            <span class="column-title">{{ header.text }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-if="operatoria_selected === 20">
      <v-row class="d-flex align-end justify-space-between mb-2 mt-1">
        <v-col cols="5">
          <h2>Mendoza Activa</h2>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Legajos seleccionados: </span>
          <v-card width="90">
            <h3 class="d-flex align-center justify-center py-1">
              {{ selected.length }}
            </h3>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Subtotal seleccionados: </span>
          <v-card width="160">
            <h3 class="d-flex align-center justify-center py-1">
              {{ calculateSelectedSubtotal() }}
            </h3>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          loading-text="Cargando legajos..."
          :headers="headersMzaActiva"
          :items="filteredItemsMzaActiva"
          item-key="id"
          show-select
          :loading="this.cargando"
          :no-data-text="''"
          :footer-props="{
            'show-current-page': true,
          }"
          v-model="selected"
          :item-class="checkFechaDesembolso"
        >
          <template v-slot:item.fecha_liquidacion="{ item }">
            <span>{{ item.fecha_liquidacion }}</span>
          </template>
          <template v-slot:item.fecha_desembolso="{ item }">
            <span>{{ item.fecha_desembolso ? 'SI' : 'NO' }}</span>
          </template>
          <template v-slot:item.data_total="{ item }">
            <span>{{ formatCurrency(item.data_total) }}</span>
          </template>
          <template
            v-for="header in headersMzaActiva"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-text-field
              v-if="header.customFilter"
              class="filter-input"
              v-model="filters[header.value]"
              append-icon="mdi-magnify"
              clearable
              hide-details
              solo
              single-line
            ></v-text-field>
            <v-text-field
              v-if="!header.customFilter"
              class="filter-input-disabled"
              hide-details
              solo
              single-line
              disabled
            ></v-text-field>
            <span class="column-title">{{ header.text }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-if="operatoria_selected === 19">
      <v-row class="d-flex align-end justify-space-between mb-2 mt-1">
        <v-col cols="5">
          <h2>ANR</h2>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Legajos seleccionados: </span>
          <v-card width="90">
            <h3 class="d-flex align-center justify-center py-1">
              {{ selected.length }}
            </h3>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Subtotal seleccionados: </span>
          <v-card width="160">
            <h3 class="d-flex align-center justify-center py-1">
              {{ calculateSelectedSubtotal() }}
            </h3>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          loading-text="Cargando legajos..."
          :headers="headersAnr"
          :items="filteredItemsAnr"
          item-key="id"
          show-select
          :loading="this.operatoria_selected ? cargando : false"
          :no-data-text="
            !this.cargando && this.success ? 'No hay resultados' : ''
          "
          :footer-props="{
            'show-current-page': true,
          }"
          v-model="selected"
        >
          <template v-slot:item.nro_legajo="{ item }">
            <span># {{ item.nro_legajo }}</span>
          </template>
          <template v-slot:item.fecha_liquidacion="{ item }">
            <span>{{ item.fecha_liquidacion }}</span>
          </template>
          <template v-slot:item.total="{ item }">
            <span>{{ formatCurrency(item.total) }}</span>
          </template>
          <template v-slot:item.data_total="{ item }">
            <span>{{ formatCurrency(item.data_total) }}</span>
          </template>
          <template v-slot:item.monto_aprobado="{ item }">
            <span>{{ formatCurrency(item.monto_aprobado) }}</span>
          </template>
          <template v-slot:item.saldoCapital="{ item }">
            <span>{{ formatCurrency(item.saldoCapital) }}</span>
          </template>
          <template
            v-for="header in headersAnr"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-text-field
              v-if="header.customFilter"
              class="filter-input"
              v-model="filters[header.value]"
              append-icon="mdi-magnify"
              clearable
              hide-details
              solo
              single-line
            ></v-text-field>
            <v-text-field
              v-if="!header.customFilter"
              class="filter-input-disabled"
              hide-details
              solo
              single-line
              disabled
            ></v-text-field>
            <span class="column-title">{{ header.text }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-if="operatoria_selected === 1371">
      <v-row class="d-flex align-end justify-space-between mb-2 mt-1">
        <v-col cols="5">
          <h2>Anticipo de Cosecha</h2>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Legajos seleccionados: </span>
          <v-card width="90">
            <h3 class="d-flex align-center justify-center py-1">
              {{ selected.length }}
            </h3>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-end pb-2">
          <span class="pr-3"> Subtotal seleccionados: </span>
          <v-card width="160">
            <h3 class="d-flex align-center justify-center py-1">
              {{ calculateSelectedSubtotal() }}
            </h3>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          loading-text="Cargando legajos..."
          :headers="headersAnticipo"
          :items="filteredItemsfinanciaminetoAnticipo"
          item-key="id"
          show-select
          :loading="this.operatoria_selected ? cargando : false"
          :no-data-text="
            !this.cargando && this.success ? 'No hay resultados' : ''
          "
          :footer-props="{
            'show-current-page': true,
          }"
          v-model="selected"
        >
          <template v-slot:item.nro_legajo="{ item }">
            <span># {{ item.nro_legajo }}</span>
          </template>
          <template v-slot:item.total="{ item }">
            <span>{{ formatCurrency(item.total) }}</span>
          </template>
          <template v-slot:item.data_total="{ item }">
            <span>{{ formatCurrency(item.data_total) }}</span>
          </template>
          <template v-slot:item.monto_aprobado="{ item }">
            <span>{{ formatCurrency(item.monto_aprobado) }}</span>
          </template>
          <template v-slot:item.saldoCapital="{ item }">
            <span>{{ formatCurrency(item.saldoCapital) }}</span>
          </template>
          <template
            v-for="header in headersAnr"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-text-field
              v-if="header.customFilter"
              class="filter-input"
              v-model="filters[header.value]"
              append-icon="mdi-magnify"
              clearable
              hide-details
              solo
              single-line
            ></v-text-field>
            <v-text-field
              v-if="!header.customFilter"
              class="filter-input-disabled"
              hide-details
              solo
              single-line
              disabled
            ></v-text-field>
            <span class="column-title">{{ header.text }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import CargaBanco from '@/components/ModalCargaBanco.vue';
import ModalInforme from '@/components/ModalInforme.vue';
import ModalInformeEnlace from '@/components/ModalInformeEnlace.vue';
import {
  API_URL,
  trilayApiNuevoLegajoEndpoint,
  datosAltaLegajos,
} from '@/common/config';
import { convertToModuloCredito } from '@/common/util';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import moment from 'moment';
import xlsx from 'xlsx';

export default {
  name: 'LiquidacionesContable',
  data: () => ({
    loadingExcel: false,
    page: 1,
    pageCount: 0,
    limit: 10,
    alta_legajo: true,
    selected: [],
    cargando: false,
    selectEstados: [],
    liquidacionesFinanciamiento: [],
    liquidacionesFinanciamientoAnticipo: [],
    liquidacionesAnr: [],
    liquidacionesMzaActiva: [],
    liquidacionesEnlace: [],
    operatoria_selected: 0,
    list_tipo_operatoria: [],
    solicitudEstado: [],
    header: {
      headers: {
        token: localStorage.getItem('token'),
      },
    },
    success: false,
    visibleModalInforme: false,
    visibleModalInformeEnlace: false,
    headersEnlace: [
      {
        text: 'N° Legajo',
        value: 'nro_legajo',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.nro_legajo.includes(value),
      },
      {
        text: 'Fecha Liquid',
        value: 'fecha_liquidacion',
        align: 'center',
        width: 140,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.fecha_liquidacion).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Estado',
        value: 'estado',
        align: 'center',
        width: 160,
        sortable: false,
      },
      {
        text: 'Beneficiario',
        value: 'nombre_beneficiario',
        align: 'center',
        sortable: true,
        customFilter: (value, item) =>
          item.nombre_beneficiario.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Nº Acta',
        value: 'nro_acta',
        align: 'center',
        width: 140,
        sortable: true,
        customFilter: (value, item) =>
          item.nro_acta.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Total',
        value: 'data_total',
        align: 'center',
        width: 160,
        sortable: false,
      },
    ],
    headersFinanciamiento: [
      {
        text: 'N° Legajo',
        value: 'nro_legajo',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.nro_legajo.includes(value),
      },
      {
        text: 'Fecha liquidacion',
        value: 'fecha_liquidacion',
        align: 'center',
        width: 140,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.fecha_liquidacion).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Con Desembolso',
        value: 'fecha_desembolso',
        align: 'center',
        width: 155,
      },
      {
        text: 'Operatoria',
        value: 'tipo_operatoria',
        align: 'center',
        width: 280,
        customFilter: (value, item) =>
          item.tipo_operatoria.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Cliente',
        value: 'data_cliente',
        align: 'center',
        sortable: true,
        customFilter: (value, item) =>
          item.data_cliente.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Total',
        value: 'data_total',
        align: 'center',
        width: 160,
        sortable: false,
      },
    ],
    headersMzaActiva: [
      {
        text: 'N° Legajo',
        value: 'nro_legajo',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.nro_legajo.includes(value),
      },
      {
        text: 'Fecha Liquid',
        value: 'fecha_liquidacion',
        align: 'center',
        width: 130,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.fecha_liquidacion).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Con Desembolso',
        value: 'fecha_desembolso',
        align: 'center',
        width: 155,
      },
      {
        text: 'Tipo Operatoria',
        value: 'tipo_operatoria',
        align: 'center',
        width: 260,
        customFilter: (value, item) =>
          item.tipo_operatoria.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Cliente',
        value: 'data_cliente',
        align: 'center',
        sortable: true,
        customFilter: (value, item) =>
          item.data_cliente.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Nº Ticket',
        value: 'data_numero_ticket',
        align: 'center',
        width: 130,
        sortable: true,
        customFilter: (value, item) =>
          item.data_numero_ticket.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Total',
        value: 'data_total',
        align: 'center',
        width: 160,
        sortable: false,
      },
    ],
    headersAnr: [
      {
        text: 'N° Legajo',
        value: 'nro_legajo',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.nro_legajo.includes(value),
      },
      {
        text: 'Fecha Liquid.',
        value: 'fecha_liquidacion',
        align: 'center',
        width: 140,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.fecha_liquidacion).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Desembolso Nro.',
        value: 'numero_desembolso',
        align: 'center',
        width: 55,
      },
      {
        text: 'Desembolsos totales',
        value: 'cantidad_desembolsos',
        align: 'center',
        width: 55,
      },
      {
        text: 'Operatoria',
        value: 'tipo_operatoria',
        align: 'center',
        width: 280,
        customFilter: (value, item) =>
          item.tipo_operatoria.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Cliente',
        value: 'data_cliente',
        align: 'center',
        width: 250,
        sortable: true,
        customFilter: (value, item) =>
          item.data_cliente.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'M. Aprobado',
        value: 'monto_aprobado',
        align: 'center',
        width: 160,
        sortable: false,
      },
      {
        text: 'Saldo Capital',
        value: 'saldoCapital',
        align: 'center',
        width: 160,
        sortable: false,
      },
      {
        text: 'Neto',
        value: 'total',
        align: 'center',
        width: 100,
        sortable: false,
      },
      {
        text: 'Total a Desembolsar',
        value: 'data_total',
        align: 'center',
        width: 150,
        sortable: false,
      },
    ],
    headersAnticipo: [
      {
        text: 'N° Legajo',
        value: 'nro_legajo',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.nro_legajo.includes(value),
      },

      {
        text: 'Desembolso Nro.',
        value: 'numero_desembolso',
        align: 'center',
        width: 55,
      },
      {
        text: 'Desembolsos totales',
        value: 'cantidad_desembolsos',
        align: 'center',
        width: 55,
      },
      {
        text: 'Fecha Desembolso',
        value: 'fecha_liquidacion',
        align: 'center',
        width: 140,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.fecha_liquidacion).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Operatoria',
        value: 'tipo_operatoria',
        align: 'center',
        width: 280,
        customFilter: (value, item) =>
          item.tipo_operatoria.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Cliente',
        value: 'data_cliente',
        align: 'center',
        width: 250,
        sortable: true,
        customFilter: (value, item) =>
          item.data_cliente.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'M. Aprobado',
        value: 'monto_aprobado',
        align: 'center',
        width: 160,
        sortable: false,
      },
      {
        text: 'Total Liquidación',
        value: 'saldoCapital',
        align: 'center',
        width: 150,
        sortable: false,
      },
      {
        text: 'Gastos Admin.',
        value: 'total',
        align: 'center',
        width: 150,
        sortable: false,
      },
      {
        text: 'Total a Desembolsar',
        value: 'data_total',
        align: 'center',
        width: 150,
        sortable: false,
      },
    ],
    filters: {},
  }),
  components: {
    CargaBanco,
    ModalInforme,
    ModalInformeEnlace,
  },
  methods: {
    // formatDate(date) {
    //   return moment(date).format('DD/MM/YYYY');
    // },
    formatDate(utc) {
      moment.locale('es');
      return utc ? moment(utc).format('DD/MM/YYYY') : '';
    },
    informeCargado() {
      console.log('EVENT EMITTER FUNCIONANDO');
      this.liquidacionesFinanciamiento = [];
      this.liquidacionesFinanciamiento = [];
      this.liquidacionesMzaActiva = [];
      this.liquidacionesAnr = [];
      this.liquidacionesFinanciamientoAnticipo = [];
      (this.operatoria_selected = 0), this.getTipoOperatorias();
      // this.getLiquidacionesFinanciamiento();
      // this.getLiquidacionesMzaActiva(20);
    },
    calculateSelectedSubtotal() {
      const subtotal = this.selected.reduce((sum, item) => {
        return sum + parseFloat(item.data_total);
      }, 0);

      return this.formatCurrency(subtotal);
    },
    formatCurrency(value) {
      const numericValue = parseFloat(value);
      if (isNaN(numericValue)) {
        return value;
      }
      const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
      });

      return formatter.format(numericValue);
    },

    checkFechaDesembolso(item) {
      return item.fecha_desembolso
        ? 'fecha-desembolso-on'
        : 'fecha-desembolso-off';
    },
    async getParam(clave) {
      const url = `${API_URL}api/parametros/${clave}`;
      axios.get(url, this.header).then((result) => {
        if (result.data.param[0].valor === 'true') {
          this.alta_legajo = true;
        } else {
          this.alta_legajo = false;
        }
      });
      console.log('getParam ALTA_LEGAJO: ', this.alta_legajo);
    },
    generarCSV() {
      const url = `${API_URL}api/contable/liquidaciones/csv`;
      const data = {
        selected: this.selected,
        operatoria_selected: this.operatoria_selected,
      };
      const link = document.createElement('a');
      axios.post(url, data, this.header).then((result) => {
        if (result.data.status === 'success') {
          const transferencias = JSON.stringify({
            version: '2.0',
            transferencias: result.data.transferencias,
          });
          const csvContent = 'data:json/csv;charset=utf-8,';
          const dataCSV = encodeURI(csvContent.concat(transferencias));
          link.setAttribute('href', dataCSV);
          link.setAttribute(
            'download',
            `SPV_Transferencias_Masivas_${moment().format(
              'YYYYMMDD_hhmmss'
            )}.json`
          );
          link.click();
        } else {
          console.error('Ocurrió un error al obtener el CSV');
        }
      });
    },
    getTipoOperatorias() {
      axios
        .get(`${API_URL}api/tipoServicios`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          const allOperatorias = response.data.tipoServicios.map((to) => ({
            value: to.id,
            text: to.nombre,
          }));
          const idsFiltrados = [13, 20, 23, 19];
          this.list_tipo_operatoria = allOperatorias.filter((operatoria) => {
            return idsFiltrados.includes(operatoria.value);
          });

          this.list_tipo_operatoria.push({
            value: 1371,
            text: 'Financiamiento -- Anticipo',
          });
          // console.log(
          //   'LISTADO DE OPERATORIAS FILTRADAS: ',
          //   this.list_tipo_operatoria
          // );
        });
    },
    async enviarTrilay() {
      this.cargando = true;
      let url;
      let result = [];
      const listar = [];
      const seleccionados = this.selected;
      for (let i in seleccionados) {
        if (!seleccionados[i].fecha_desembolso)
          listar.push(seleccionados[i].id);
      }
      if (listar.length > 0) {
        this.$swal
          .fire({
            title: 'Legajos sin fecha de desembolso:',
            html: listar,
            confirmButtonColor: '#d33',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.cargando = false;
              return;
            }
          });
      } else {
        try {
          if (this.operatoria_selected === 23) {
            // url = `${API_URL}api/liquidacion/trilayEnlace`;
            // this.selected.forEach((l) => {
            //   const data = {
            //     lcc_id: l.nro_legajo,
            //   };
            //   promises.push(axios.post(url, data, this.header));
            // });
            console.log('----> enlace');
          } else {
            url = `${API_URL}api/liquidacion/trilay`;
            for (let index = 0; index < this.selected.length; index++) {
              const element = this.selected[index];

              const data = {
                liquidacion: element,
              };
              const resp = await this.enviarAsiento(url, data, this.header);

              result.push({
                cliente: element.data_cliente,
                legajo: element.nro_legajo,
                codMovimiento: 0,
              });
              if (resp.codMovimiento)
                result[index].codMovimiento = resp.codMovimiento;

              console.log('EnviarTrilay > RESPUESTA TOTAL: ', result);
            }
            this.cargando = false;
          }
        } catch (e) {
          console.log(e);
        }
        const generateListItems = (items) => {
          return items
            .map((item) => {
              return `<li><b>${item.legajo}</b> - ${item.cliente} - Código Movimiento: ${item.codMovimiento}</li>`;
            })
            .join('');
        };
        const html = `<ul>${generateListItems(result)} </ul>`;
        this.$swal
          .fire({
            title: 'Resultados:',
            html: html,
            cancelButtonColor: '#3085d6',
            showCancelButton: true,
            cancelButtonText: 'OK',
            confirmButtonText: 'Save',
            confirmButtonColor: '#d33',
          })
          .then((result) => {
            if (result.isConfirmed) {
              const doc = new jsPDF();

              doc.html(html, {
                callback: function (doc) {
                  // Save the PDF
                  doc.save('Asientos.pdf');
                },
                x: 15,
                y: 15,
                width: 170, //target width in the PDF document
                windowWidth: 650, //window width in CSS pixels
              });
            }
          });
      }
    },
    async enviarAsiento(url, data, header) {
      let resp;
      await axios
        .post(url, data, header)
        .then((response) => {
          console.log('enviarAsiento - Respuesta: '), response;
          if (response.data.status === 'success') {
            resp = {
              mensaje: `ok`,
              estado: 'Success',
              codMovimiento: response.data.movimiento,
            };
            return;
          }
          if (response.status !== 'error') {
            resp = {
              mensaje: `Mal`,
              estado: 'success',
            };
          } else {
            resp = {
              mensaje: 'Ocurrió un error al generar el movimiento Trilay',
              estado: 'Error',
            };
          }
        })
        .catch((error) => {
          console.log(error);
          resp = {
            mensaje:
              'TRILAY: Ocurrió un error al generar el movimiento en Trilay',
            estado: 'Error',
          };
        });
      return resp;
    },
    async enviarAlta(url, data, header) {
      let resp;
      await axios
        .post(url, data, header)
        .then((response) => {
          if (
            response.data.CodCredito === undefined &&
            response.data.status !== 'error'
          ) {
            resp = {
              mensaje: `Falta CodCredito`,
              estado: 'Succes',
            };
            return;
          }
          if (response.data.status !== 'error') {
            resp = {
              mensaje: `${response.data.message} ${response.data.CodCredito} en TRILAY`,
              estado: 'success',
            };
          } else {
            resp = {
              mensaje: `Ocurrió un error al generar el legajo en Trilay, ${response.data.message}`,
              estado: 'Error',
            };
          }
        })
        .catch((error) => {
          console.log(error);
          resp = {
            mensaje: 'TRILAY: Ocurrió un error al generar el legajo en Trilay',
            estado: 'Error',
          };
        });
      return resp;
    },
    async buscarDatos(url, data, header) {
      let resp;
      await axios
        .post(url, data, header)
        .then((response) => {
          if (response.status !== 'error') {
            resp = {
              data: response.data.liquidaciones,
              estado: 'success',
            };
          } else {
            resp = {
              mensaje: 'Ocurrió un error',
              estado: 'Error',
            };
          }
        })
        .catch((error) => {
          console.log(error);
          resp = {
            mensaje: 'Ocurrió un error al generar al recuperar datos',
            estado: 'Error',
          };
        });
      return resp;
    },
    async enviarModuloCredito() {
      this.cargando = true;

      const listar = [];
      const seleccionados = this.selected;
      for (let i in seleccionados) {
        if (!seleccionados[i].fecha_desembolso)
          listar.push(seleccionados[i].id);
      }

      if (listar.length > 0) {
        this.$swal
          .fire({
            title: 'Legajos sin fecha de desembolso:',
            html: listar,
            confirmButtonColor: '#d33',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.cargando = false;
              return;
            }
          });
      } else {
        const urlI = `${API_URL}${datosAltaLegajos}`;
        const urlII = `${API_URL}${trilayApiNuevoLegajoEndpoint}`;
        const header = {
          headers: {
            token: localStorage.getItem('token'),
          },
        };
        try {
          let result = [];
          const legajo = this.selected;
          const ids = legajo.map((el) => ({
            id: `${el.id}`,
            cliente_id: `${el.cliente.id}`,
            lineaCredito_id: `${el.lineaCredito_id}`,
            tipo_operatoria: `${el.tipo_operatoria}`,
          }));
          const legajoOperatoria = { ...{ ids }, tabla: 'financiamiento' };

          const { data: datos } = await this.buscarDatos(
            urlI,
            legajoOperatoria,
            header
          );

          for (const i in datos) {
            if (!datos[i].cliente.cuit) {
              result.push({
                cliente: datos[i].cliente.razonSocial
                  ? datos[i].cliente.razonSocial
                  : `${datos[i].cliente.apellido} ${datos[i].cliente.nombre}`,
                legajo: datos[i].id,
                mensaje: 'No posee CUIT/CUIL',
                estado: 'Error',
              });
            } else {
              const data = convertToModuloCredito(datos[i]);

              if (data.error) {
                result.push({
                  cliente: datos[i].cliente.razonSocial
                    ? datos[i].cliente.razonSocial
                    : `${datos[i].cliente.apellido} ${datos[i].cliente.nombre}`,
                  legajo: datos[i].id,
                  mensaje: 'Error de validación',
                  estado: 'Error',
                });
              }

              const resp = await this.enviarAlta(urlII, data, header);
              result.push({
                cliente: datos[i].cliente.razonSocial
                  ? datos[i].cliente.razonSocial
                  : `${datos[i].cliente.apellido} ${datos[i].cliente.nombre}`,
                legajo: datos[i].id,
                mensaje: resp.mensaje,
                estado: resp.estado,
              });
            }
            this.cargando = false;
          }

          const generateListItems = (items) => {
            return items
              .map((item) => {
                return `<li><b>${item.legajo} - </b> ${item.cliente} - ${item.mensaje} - ${item.estado}</li>`;
              })
              .join('');
          };

          const html = `
          <ul>
            ${generateListItems(result)}
          </ul>
          `;
          this.$swal
            .fire({
              title: 'Resultados:',
              html: html,
              cancelButtonColor: '#3085d6',
              showCancelButton: true,
              cancelButtonText: 'OK',
              confirmButtonText: 'Save',
              confirmButtonColor: '#d33',
            })
            .then((result) => {
              if (result.isConfirmed) {
                const doc = new jsPDF();

                doc.html(html, {
                  callback: function (doc) {
                    // Save the PDF
                    doc.save('AltaLegajo.pdf');
                  },
                  x: 15,
                  y: 15,
                  width: 170, //target width in the PDF document
                  windowWidth: 650, //window width in CSS pixels
                });
              }
            });
          this.cargando = false;
        } catch (error) {
          if (error.message.includes('codigo_cliente_trilay')) {
            this.$swal.fire(
              'Modulo de Credito',
              'El legajo no tiene un codigo de cliente asociado en Trilay',
              'error'
            );
          } else {
            this.$swal.fire(
              'Modulo de Credito',
              'Ocurrió un error al generar el legajo en Trilay',
              'error'
            );
          }
          this.cargando = false;
        }
      }
    },
    calcularTotales(gastos) {
      let honorariosMfs = 0;
      let iibbHonorariosMfs = 0;
      let honorariosMfsIva = 0;
      let totalHonorariosMfs = 0;
      let otrosGastos = 0;
      let ivaOtrosGastos = 0;
      let iibbOtrosGastos = 0;
      let totalOtrosGastos = 0;
      let totalGeneral = 0;
      let totalIibb = 0;
      let totalIva = 0;
      let totalNeto = 0;
      gastos.forEach((g) => {
        honorariosMfs =
          g.descripcion === 'Gastos Administrativos'
            ? honorariosMfs + parseFloat(g.administrativos.administrativo)
            : honorariosMfs;

        iibbHonorariosMfs =
          g.descripcion === 'Gastos Administrativos'
            ? iibbHonorariosMfs + parseFloat(g.iibb)
            : iibbHonorariosMfs;

        honorariosMfsIva =
          g.descripcion === 'Gastos Administrativos'
            ? honorariosMfsIva + parseFloat(g.administrativos.iva)
            : honorariosMfsIva;

        totalHonorariosMfs =
          honorariosMfs + honorariosMfsIva + iibbHonorariosMfs;
        //--------
        otrosGastos =
          g.descripcion === 'Otorgamiento'
            ? otrosGastos + parseFloat(g.administrativos.otorgamiento)
            : otrosGastos;

        ivaOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? ivaOtrosGastos + parseFloat(g.administrativos.iva)
            : ivaOtrosGastos;

        iibbOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? iibbOtrosGastos + parseFloat(g.iibb)
            : iibbOtrosGastos;

        totalOtrosGastos = otrosGastos + ivaOtrosGastos;

        totalGeneral = totalHonorariosMfs + totalOtrosGastos + iibbOtrosGastos;
        totalIibb = iibbHonorariosMfs + iibbOtrosGastos;
        totalIva = honorariosMfsIva + ivaOtrosGastos;
        totalNeto = otrosGastos + honorariosMfs;
      });
      return {
        honorariosMfs,
        iibbHonorariosMfs,
        honorariosMfsIva,
        totalHonorariosMfs,
        otrosGastos,
        ivaOtrosGastos,
        iibbOtrosGastos,
        totalOtrosGastos,
        totalGeneral,
        totalIibb,
        totalIva,
        totalNeto,
      };
    },
    async getLiquidacionesAnr() {
      this.cargando = true;
      const url = `${API_URL}api/liquidacion/contableAnr`;

      await axios.get(url, this.header).then((response) => {
        if (response.data.status === 'success') {
          const { liquidaciones } = response.data;

          this.liquidacionesAnr = liquidaciones.map((l) => ({
            gastos: this.calcularTotales(l.totalGastos),
            ...l,
            nro_legajo: l.id,
            data_cliente:
              l.cliente.nombre === ''
                ? l.cliente.razonSocial
                : `${l.cliente.nombre} ${l.cliente.apellido}`,
            fecha_liquidacion: l.fecha_liquidacion
              ? this.formatDate(l.fecha_liquidacion)
              : '',
            lineaCredito_id: l.lineaCredito_id,
            monto_aprobado: l.montoAprobado || 0,
            total: l.desembolso[0].monto || 0,
            tipo_operatoria: l.tipo_operatoria,
            cantidad_desembolsos: l.cantidad_desembolsos,
            numero_desembolso: l.desembolso[0].numeroDesembolso,
            data_total: l.liquidaciones[0].total || 0,
          }));
          moment.locale('es');
          console.log(
            'getLiquidacionesFinanciamiento - ITEMS DATA LENGTH: ',
            this.liquidacionesAnr.length
          );
          this.alta_legajo = true;
          this.cargando = false;
        }
      });
    },
    async getLiquidacionesFinanciamiento() {
      this.cargando = true;
      const url = `${API_URL}api/liquidacion/contableFinanciamiento`;
      const data = {
        month: 7,
        contable: true,
        lineaCredito_id: this.tipo_operatoria ? this.tipo_operatoria : '',
      };

      await axios.post(url, data, this.header).then((response) => {
        if (response.data.status === 'success') {
          const { data: dataResponse } = response;
          const {
            page: pageResponse,
            limit: limitResponse,
            pageCount: pageCountResponse,
          } = dataResponse;
          this.liquidacionesFinanciamiento = response.data.liquidaciones.map(
            (l) => ({
              ...l,
              nro_legajo: l.id,
              data_cliente:
                l.cliente.nombre === ''
                  ? l.cliente.razonSocial
                  : `${l.cliente.nombre} ${l.cliente.apellido}`,
              fecha_liquidacion: l.fecha_liquidacion
                ? this.formatDate(l.fecha_liquidacion)
                : '',
              lineaCredito_id: l.lineaCredito_id,
              data_neto: l.liquidacion[0].total_iva.toFixed(2),
              data_total: l.liquidacion[0].total.toFixed(2),
              data_efectivo: l.liquidacion[0].efectivo.toFixed(2),
              data_billetera: l.liquidacion[0].billetera.toFixed(2),
              data_cred_fiscal: l.liquidacion[0].credito_fiscal.toFixed(2),
              tipo_operatoria: l.tipo_operatoria,
              fecha_desembolso: l.fecha_desembolso,
            })
          );
          moment.locale('es');
          console.log(
            'getLiquidacionesFinanciamiento - ITEMS DATA LENGTH: ',
            this.liquidacionesAnr.length
          );
          this.alta_legajo = true;
          this.cargando = false;
        }
      });
    },
    //operatoria especial -financiamiento / ANR
    async getLiquidacionesFinanciamientoAnticipo() {
      this.cargando = true;
      const url = `${API_URL}api/liquidacion/contableFinanciaminetoAnticipo`;

      await axios.get(url, this.header).then((response) => {
        if (response.data.status === 'success') {
          const { liquidaciones } = response.data;

          this.liquidacionesFinanciamientoAnticipo = liquidaciones.map((l) => ({
            // gastos: this.calcularTotales(l.totalGastos),
            ...l,
            total: l.desembolso[0].gastosAdmin,
            nro_legajo: l.id,
            data_cliente:
              l.cliente.nombre === ''
                ? l.cliente.razonSocial
                : `${l.cliente.nombre} ${l.cliente.apellido}`,
            fecha_liquidacion: l.desembolso[0].fecha
              ? moment(l.desembolso[0].fecha, 'DD-MM-YYYY').format('DD/MM/YYYY')
              : '',
            lineaCredito_id: l.lineaCredito_id,
            monto_aprobado: l.montoAprobado,
            data_total:
              l.desembolso[0].monto +
              l.desembolso[0].gastos -
              l.desembolso[0].gastosAdmin,
            tipo_operatoria: l.tipo_operatoria,
            cantidad_desembolsos: l.cantidad_desembolsos,
            numero_desembolso: l.desembolso[0].numeroDesembolso,
            saldoCapital: l.desembolso[0].monto + l.desembolso[0].gastos,
          }));
          moment.locale('es');
          console.log(
            'getLiquidacionesFinanciamiento - ITEMS DATA LENGTH: ',
            this.liquidacionesAnr.length
          );
          this.alta_legajo = true;
          this.cargando = false;
        }
      });
    },
    async getLiquidacionesMzaActiva(lccid) {
      this.cargando = true;
      const url = `${API_URL}api/liquidacion/contable`;
      const data = {};
      if (lccid) {
        data.lcc_id = lccid;
      }
      await axios.post(url, data, this.header).then((response) => {
        if (response.data.status === 'success') {
          this.success = true;
          moment.locale('es');
          this.liquidacionesMzaActiva = response.data.liquidaciones.map(
            (l) => ({
              ...l,
              nro_legajo: l.lineacreditocliente_id.id,
              data_cliente: l.cliente.valor,
              fecha_liquidacion: l.createdAt,
              data_neto: l.total_iva.toFixed(2),
              data_total: l.total.toFixed(2),
              data_efectivo: l.efectivo.toFixed(2),
              data_billetera: l.billetera.toFixed(2),
              data_cred_fiscal: l.credito_fiscal.toFixed(2),
              data_numero_ticket: l.numero_ticket.valor,
              tipo_operatoria: l.lineacreditocliente_id.lineaCredito_id.nombre,
              fecha_desembolso: l.lineacreditocliente_id.fecha_desembolso,
            })
          );
          console.log(
            'getLiquidacionesMzaActiva  - ITEMS DATA LENGTH: ',
            this.liquidacionesMzaActiva.length
          );
          this.alta_legajo = false;
          this.cargando = false;
        }
      });
      this.cargando = false;
    },
    async getLiquidacionesEnlace() {
      this.cargando = true;
      const url = `${API_URL}api/liquidacion/informeProgramaEnlace`;
      await axios.post(url, {}, this.header).then((response) => {
        this.liquidacionesEnlace =
          response.data.lineaCreditoClienteFiltrada.map((el, index) => ({
            ...el,
            id: index,
            data_total: el.total,
            // CHANGE this to save dates as numbers, like Financiamiento and Mza Activa
            fecha_liquidacion: new Date(Date(el.fecha_liquidacion)).getTime(),
            cero: 0.0,
            estado: this.solicitudEstados.find(
              (elem) => elem.id === el.estado_legajo
            ).nombre,
          }));
      });

      console.log(
        'getLiquidacionesEnlace - ITEMS DATA LENGTH: ',
        this.liquidacionesEnlace.length
      );
      this.cargando = false;
    },
    async getEstados() {
      const consulta = {
        method: 'GET',
        url: `${API_URL}api/solicitudEstado`,
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const { data } = await axios(consulta);
      this.solicitudEstados = data.solicitudEstado;
      this.selectEstados = this.solicitudEstados.map((el) => ({
        text: el.nombre,
        value: el.nombre,
      }));
      this.selectEstados.unshift({ text: 'General', value: 'General' });
    },
    cleanVariablesAndFilters() {
      this.selected = [];
      this.liquidacionesFinanciamiento = [];
      this.liquidacionesMzaActiva = [];
      this.liquidacionesEnlace = [];
      this.liquidacionesAnr = [];
      this.filteredItemsFinanciamiento;
      this.filteredItemsAnr;
      this.filteredItemsMzaActiva;
      this.filteredItemsEnlace;
    },
    switchTipoOperatoria() {
      this.$forceUpdate();
      this.cleanVariablesAndFilters();
      switch (this.operatoria_selected) {
        case 13:
          this.getLiquidacionesFinanciamiento();
          break;
        case 19:
          this.getLiquidacionesAnr();
          break;
        case 20:
          this.getLiquidacionesMzaActiva(20);
          break;
        case 23:
          this.getLiquidacionesEnlace();
          break;
        case 1371:
          //1371 es una forma de representar 13 financiamiento y 71 oper anticipo coceche(esepcion)
          this.getLiquidacionesFinanciamientoAnticipo();
          break;
        default:
          break;
      }
    },
    async exportarExcel() {
      this.loadingExcel = true;

      let list = this.liquidacionesAnr.map((cc) => ({
        Cliente: cc.data_cliente,
        Fecha: cc.fecha_liquidacion,
        Monto: cc.data_total || 0,
        Capital: cc.saldoCapital || 0,
        Honorarios: cc.gastos.honorariosMfs + cc.gastos.otrosGastos,
        Gastos:
          cc.gastos.iibbHonorariosMfs +
          cc.gastos.iibbOtrosGastos +
          cc.gastos.ivaOtrosGastos +
          cc.gastos.honorariosMfsIva,
      }));
      let nombre = `Listado Créditos ANR`;
      const cs = xlsx.utils.json_to_sheet(list);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, cs, nombre);
      xlsx.writeFile(
        wb,
        `Desembolsos -Trilay ${moment().format('DD/MM/YYYY')}.xlsx`
      );
      this.loadingExcel = false;
      this.selected = [];
    },
  },
  computed: {
    filteredItemsFinanciamiento() {
      if (!this.operatoria_selected) return [];
      const filteredItems = this.liquidacionesFinanciamiento.filter((item) => {
        if (this.filters['nro_legajo']) {
          const filterValue = parseInt(this.filters['nro_legajo'], 10);
          return item['nro_legajo'] === filterValue;
        }

        return Object.keys(this.filters).every((key) => {
          if (key === 'nro_legajo') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          if (key === 'fecha_liquidacion') {
            const formattedItemDate = moment(item.fecha_liquidacion).format(
              'DD/MM/YYYY'
            );
            return formattedItemDate.includes(filterValue);
          }
          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      return filteredItems;
    },
    filteredItemsMzaActiva() {
      if (!this.operatoria_selected) return [];
      const filteredItems = this.liquidacionesMzaActiva.filter((item) => {
        if (this.filters['nro_legajo']) {
          const filterValue = parseInt(this.filters['nro_legajo'], 10);
          return item['nro_legajo'] === filterValue;
        }
        return Object.keys(this.filters).every((key) => {
          if (key === 'nro_legajo') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          if (key === 'fecha_liquidacion') {
            const formattedItemDate = moment(item.fecha_liquidacion).format(
              'DD/MM/YYYY'
            );
            return formattedItemDate.includes(filterValue);
          }

          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      return filteredItems;
    },
    filteredItemsEnlace() {
      if (!this.operatoria_selected) return [];
      const filteredItems = this.liquidacionesEnlace.filter((item) => {
        if (this.filters['nro_legajo']) {
          const filterValue = parseInt(this.filters['nro_legajo'], 10);
          return item['nro_legajo'] === filterValue;
        }
        return Object.keys(this.filters).every((key) => {
          if (key === 'nro_legajo') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          if (key === 'fecha_liquidacion') {
            const formattedItemDate = moment(item.fecha_liquidacion).format(
              'DD/MM/YYYY'
            );
            return formattedItemDate.includes(filterValue);
          }

          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      return filteredItems;
    },
    filteredItemsAnr() {
      if (!this.operatoria_selected) return [];
      const filteredItems = this.liquidacionesAnr.filter((item) => {
        if (this.filters['nro_legajo']) {
          const filterValue = parseInt(this.filters['nro_legajo'], 10);
          return item['nro_legajo'] === filterValue;
        }

        return Object.keys(this.filters).every((key) => {
          if (key === 'nro_legajo') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          if (key === 'fecha_liquidacion') {
            const formattedItemDate = moment(item.fecha_liquidacion).format(
              'DD/MM/YYYY'
            );
            return formattedItemDate.includes(filterValue);
          }
          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      return filteredItems;
    },
    filteredItemsfinanciaminetoAnticipo() {
      if (!this.operatoria_selected) return [];
      const filteredItems = this.liquidacionesFinanciamientoAnticipo.filter(
        (item) => {
          if (this.filters['nro_legajo']) {
            const filterValue = parseInt(this.filters['nro_legajo'], 10);
            return item['nro_legajo'] === filterValue;
          }

          return Object.keys(this.filters).every((key) => {
            if (key === 'nro_legajo') return true;
            const filterValue = this.filters[key];
            if (!filterValue) return true;

            if (key === 'fecha_liquidacion') {
              const formattedItemDate = moment(item.fecha_liquidacion).format(
                'DD/MM/YYYY'
              );
              return formattedItemDate.includes(filterValue);
            }
            return item[key].toLowerCase().includes(filterValue.toLowerCase());
          });
        }
      );
      return filteredItems;
    },
  },
  created() {
    this.getEstados();
    this.getTipoOperatorias();
    this.getParam('alta_legajo_credito_activado');
    // this.operatoria_selected = 13;
    //this.getLiquidacionesFinanciamiento();
    console.log('Created ALTA_LEGAJO: ', this.alta_legajo);
  },
};
</script>
<style>
th,
td {
  padding: 0 3px !important;
}
.row .mb-3 {
  align-items: center !important;
}
i {
  margin-left: 5px !important;
}
input {
  font-size: 14px;
  font-weight: 500;
  color: #1976d2 !important;
}
.v-input__control {
  margin: 5px;
}
.v-input__slot {
  height: 30px;
  min-height: 30px !important;
  margin: 6px 0 0 0;
  padding: 0 6px !important;
}
.filter-input-disabled .v-input__control .v-input__slot {
  background-color: rgb(239, 239, 239) !important;
  visibility: hidden !important;
}
.column-title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 1rem !important;
}
.select-operatoria {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
}
.select-operatoria div {
  max-width: 400px !important;
}
.fecha-desembolso-off {
  color: rgb(216, 34, 34);
}
.fecha-desembolso-on {
  color: rgb(52, 192, 68);
}
.disabled-table {
  opacity: 0.5;
  pointer-events: none;
}
</style>
